<template>
  <div>
    <van-button type="info" round block @click="onRemoveCache">
      清空缓存
    </van-button>
  </div>
</template>
<script>
import storage from 'store'

export default {
  data() {
    return {
      flag: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    onRemoveCache() {
      storage.clearAll()
    }
  }
}
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
}
html,
body,
#app {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* background: url(http://zhiye.nj12320.org:7780/exam-cdn/exam/20221202233157.jpg)
    no-repeat; */
}
.background {
  /* background: url(~@/assets/background.jpg) no-repeat; */
  background-position: center;
  background-size: cover;
}
</style>
